<template>
    <div class="home">
        <div class="banner">
            <el-carousel
                indicator-position="none"
                ref="carousel"
                arrow="never"
                @change="change"
            >
                <template v-for="i in [0, 2]">
                    <el-carousel-item :key="i + 1">
                        <div class="bann-itm" :class="{ bg2: i % 2 == 0 }">
                            <div class="flex content-p">
                                <div
                                    class="text-box"
                                    :class="fadeClass(i + 1, 1)"
                                >
                                    <p>好生活·经纪人</p>
                                    <span>高额佣金 轻松推介 快速兑现</span>
                                    <div
                                        class="flex btn-box"
                                        :class="fadeClass(i + 1, 2)"
                                    >
                                        <div
                                            class="dowload-btn"
                                            @click="toCarousel(4)"
                                        >
                                            立即下载
                                        </div>
                                        <div class="arrow-btn"></div>
                                    </div>
                                </div>
                                <div
                                    class="img-box"
                                    :class="fadeClass(i + 1, 2)"
                                >
                                    <img src="@/assets/img/banner-item1.png" />
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item :key="i + 2">
                        <div class="bann-itm itm2" :class="{ bg2: i % 2 == 0 }">
                            <div class="content-p flex">
                                <div
                                    class="text-box"
                                    :class="fadeClass(i + 2, 1)"
                                >
                                    <p>好生活·好房</p>
                                    <span>让您的房产交易更加便捷</span>
                                    <div
                                        class="flex btn-box"
                                        :class="fadeClass(i + 2, 2)"
                                    >
                                        <div
                                            class="dowload-btn btn2"
                                            @click="toCarousel(3)"
                                        >
                                            立即下载
                                        </div>
                                        <div class="arrow-btn btn2"></div>
                                    </div>
                                </div>
                                <div
                                    class="img-box"
                                    :class="fadeClass(i + 2, 2)"
                                >
                                    <img src="@/assets/img/banner-item2.png" />
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
        <div class="content mar-t-80 t-al-c">
            <div class="mar-b-20">
                <img src="@/assets/img/detail-title1.png" height="93px" />
            </div>
            <div>
                <img src="@/assets/img/detail-con1.jpg" width="100%" />
            </div>
        </div>
        <div class="content mar-t-80 t-al-c">
            <div class="mar-b-20">
                <img src="@/assets/img/detail-title2.png" height="96px" />
            </div>
            <div>
                <div class="info-box">
                    <p class="title">立足中国，辐射东南亚</p>
                    <span class="tip"
                        >布局五大经济圈，深耕100+核心城市群。重点发力大湾区，形成东南亚辐射基地。</span
                    >
                    <div class="flex num-box">
                        <div v-for="(item, idx) in numList" :key="idx">
                            <p>
                                <countTo
                                    :startVal="0"
                                    :endVal="item.num"
                                    :duration="3000"
                                    :ref="'countTo' + idx"
                                ></countTo>
                                {{ item.unit }}
                            </p>
                            <span>{{ item.info }}</span>
                        </div>
                    </div>
                </div>

                <img src="@/assets/img/detail-con2.jpg" width="100%" />
            </div>
        </div>
        <div class="content mar-t-80 business-box flex">
            <div class="model-tit">
                <img src="@/assets/img/business-title.png" width="180px" />
            </div>
            <div v-for="(item, idx) in businessList" :key="idx">
                <ul class="cube">
                    <li></li>
                    <li>
                        <p class="title">{{ item.title }}</p>
                        <span class="tip">{{ item.info }}</span>
                    </li>
                    <li>
                        <p class="title">{{ item.title }}</p>
                        <span class="title-en">{{
                            item.EN.toUpperCase()
                        }}</span>
                        <span class="tip">{{ item.info }}</span>
                        <img class="icon" :src="item.icon" />
                    </li>
                    <li></li>
                </ul>
            </div>
        </div>
        <div class="mar-t-80 t-al-c po-r content">
            <div class="mar-b-47">
                <img src="@/assets/img/detail-title3.png" height="96px" />
            </div>
            <el-tabs
                class="content mar-b-31"
                v-model="activeName"
                @tab-click="clickTab"
            >
                <el-tab-pane
                    :label="item"
                    :name="idx + ''"
                    v-for="(item, idx) in projectList"
                    :key="idx"
                ></el-tab-pane>
            </el-tabs>
            <div class="arrow-box content">
                <img
                    class="f-l"
                    src="@/assets/img/arrow-left-icon.png"
                    width="18px"
                    @click="$refs.tabsCarousel.prev()"
                />
                <img
                    class="f-r"
                    src="@/assets/img/arrow-right-icon.png"
                    width="18px"
                    @click="$refs.tabsCarousel.next()"
                />
            </div>
            <el-carousel
                indicator-position="none"
                ref="tabsCarousel"
                arrow="never"
                @change="tabsChange"
                class="mar-t-47 tabs-carousel"
                :autoplay="false"
            >
                <el-carousel-item v-for="i in 7" :key="i">
                    <div class="content">
                        <tabsContent
                            :index="i - 1"
                            flexPic="true"
                            @clickBtn="
                                (idx) => {
                                    toCarousel(idx);
                                }
                            "
                        ></tabsContent>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <transition name="fade">
            <div class="qrcode-box" v-if="qrShow">
                <div class="qr-item">
                    <img
                        src="@/assets/img/qrcode-house.png"
                        width="140"
                        height="140"
                    />
                    <p>好生活·好房APP下载</p>
                </div>
                <div class="qr-item item2">
                    <img
                        src="@/assets/img/qrcode-broke.jpg"
                        width="140"
                        height="140"
                    />
                    <p>好生活·经纪人APP下载</p>
                </div>
            </div>
        </transition>
    </div>
</template> 

<script>
import {carousel,carouselItem,tabs,tabPane} from "element-ui"
import countTo from 'vue-count-to'
import tabsContent from '@/components/tabsContent'
import icon1 from '@/assets/img/business-icon1.png'
import icon2 from '@/assets/img/business-icon2.png'
import icon3 from '@/assets/img/business-icon3.png'
import icon4 from '@/assets/img/business-icon4.png'
import icon5 from '@/assets/img/business-icon5.png'
import icon6 from '@/assets/img/business-icon6.png'
import icon7 from '@/assets/img/business-icon7.png'


export default {
  name: 'Home',
  components: {
      elCarousel:carousel,
      elCarouselItem:carouselItem,
      elTabs:tabs,
      elTabPane:tabPane,
      countTo,
      tabsContent,
  },
  data(){
      return {
          scrollTop:0,
          qrShow:false,
          index:1,
          oldIndex:0,
          loadNum:false,
          numList:[
              {num:6000,unit:'家+',info:'合作伙伴'},
              {num:50,unit:'万+',info:'高能合伙人'},
              {num:3000,unit:'万+',info:'核心用户'},
              {num:1600,unit:'亿+',info:'累计交易额'},
              {num:117,unit:'%',info:'营业额复合增长'},
              {num:2000,unit:'万+',info:'人均销售额'},
          ],
          businessList:[
               {title:'好生活·新房' ,EN:'new house',info:'房产整合服务平台。为开发商提供全链条自助服务；为合伙人提供交易及赋能；为消费者打造一站式服务',icon:icon1},         
               {title:'好生活·二手房',EN:'second-hand house',info:'专业一站式线上服务，海量房源，任你挑选，诚信交易，后顾无忧。专业经纪人高品质服务，选心仪房源，我来保驾护航。',icon:icon2},         
               {title:'好生活·好汽车',EN:'Good car ',info:'新能源汽车交易服务整合平台',icon:icon3},         
               {title:'好生活·好租赁',EN:'Good lease',info:'提供房产、商业、产业园、写字楼等整合服务及交易平台',icon:icon4},         
               {title:'好生活·好康养',EN:'Good health care',info:'以养康为核心提供养、康、保产品交易服务',icon:icon5},         
               {title:'好生活·好保险',EN:'Good insurance ',info:'深入结合平台场景，为交易过程及用户，提供保险交易及保障服务',icon:icon6},         
               {title:'好生活·好金融',EN:'Good finance',info:'服务房、车、养等全合成经，提供，货、保等一系列金融服务',icon:icon7},         
          ],
          projectList:['资源赋能','效率赋能','好生活·好房','好生活·经纪人','合伙人商学院','开发商Saas','好生活管家 '],
          activeName:'0'
      }
  },
  created(){
      
      window.addEventListener('scroll', this.onScroll)
      this.loadNum  = false;
  },
  computed:{
      fade(){
          return {}
      },
      
  },

//    beforeRouteLeave(to, form, next) {
//         window.removeEventListener('scroll')
//         window.onscroll = null;
//     next()
//   },
  methods:{
      toCarousel(type){
          this.$router.push({
              path:'./Proservice',
              query:{type}
          })
      },
    onScroll(){
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if(this.scrollTop > 800 && !this.loadNum){
            for (let i=0;i<6;i++){
                if(this.$refs['countTo'+i].length){
                     this.$refs['countTo'+i][0].start()
                }
               
            }
            this.loadNum = true;
        }

         this.qrShow =(this.scrollTop >document.documentElement.clientHeight) || (this.scrollTop >document.body.clientHeight) 
        
    },
      fadeClass(idx,type){
          //let isLeft = this.index - this.oldIndex == 1 || this.index - this.oldIndex<-1;
          let isThis = idx == this.index;
          //return {[`fadeLf${type}`]:isLeft && isThis,[`fadeRi${type}`]:!isLeft && isThis}
          return {[`fadeLf${type}`]:isThis}
      },
 
      change(index,oldIndex){
          this.oldIndex = oldIndex+1;
          this.index = index+1;
      },
      clickTab(){
          this.$refs.tabsCarousel.setActiveItem(this.activeName)
      },
      tabsChange(idx){
          this.activeName = idx+'';
      },
  }
}
</script>
<style lang="scss" scoped>
.banner {
    // background: linear-gradient(#f6faff 95%, #ffffff 5%);
    // position: relative;
    // height: 650px;
    // &::before {
    //     background: url("~@/assets/img/banner.png") no-repeat top center;
    //     background-size: auto 620px;
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     content: "";
    // }
}
.bann-itm {
    background: url("~@/assets/img/banner.png") no-repeat top center;
    position: relative;
    height: 650px;
    &::before {
        background: linear-gradient(#f6faff 95%, #ffffff 5%);
        //background-size: auto 620px;
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
    }

    &.itm2 {
        background: url("~@/assets/img/banner2.png") no-repeat top center;
        &::before {
            background: linear-gradient(#fff7f2 95%, #ffffff 5%);
        }
        .dowload-btn {
            background: #f76f3c;
            box-shadow: 0px 12px 40px 0px rgba(247, 111, 60, 0.32);
        }
        .arrow-btn {
            background-image: url("~@/assets/img/arrow-btn-icon2.png");
            box-shadow: 0px 12px 40px 0px rgba(247, 111, 60, 0.22);
        }
    }

    .text-box {
        padding-top: 136px;
        flex: 1;
        p {
            font-weight: bold;
            color: #002062;
            line-height: 62px;
            letter-spacing: 5px;
            font-size: 58px;
            margin-bottom: 22px;
        }
        span {
            font-size: 22px;
            font-weight: 400;
            color: #002062;
            line-height: 42px;
            letter-spacing: 2px;
        }
    }
    .img-box {
        flex: 1;
        //opacity: 0;
        > img {
            margin-top: 30px;
            height: 630px;
        }
    }
}
.dowload-btn {
    width: 200px;
    height: 78px;
    background: #4898ff;
    box-shadow: 0px 12px 40px 0px rgba(72, 152, 255, 0.32);
    border-radius: 39px;
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
    letter-spacing: 2px;
    text-align: center;
    padding: 15px 0px;
    margin-right: 24px;
}
.arrow-btn {
    width: 78px;
    height: 78px;
    box-shadow: 0px 12px 40px 0px rgba(72, 152, 255, 0.12);
    border-radius: 39px;
    background: url("~@/assets/img/arrow-btn-icon.png") no-repeat center #fff;
    background-size: 28px 23px;
}
.info-box {
    position: absolute;
    width: 366px;
    text-align: left;
    padding-top: 87px;

    p.title {
        font-weight: bold;
        color: #002062;
        line-height: 35px;
        font-size: 26px;
        margin-bottom: 16px;
    }
    span.tip {
        color: #002062;
        line-height: 30px;
        font-size: 18px;
    }
    .num-box {
        flex-wrap: wrap;
        margin-top: 80px;

        > div {
            width: 50%;
            position: relative;
            padding: 10px 0;
            margin-bottom: 30px;

            &::before {
                content: "";
                width: 77px;
                position: absolute;
                height: 1px;
                bottom: 0;
                background: linear-gradient(
                    26deg,
                    #4898ff 0%,
                    rgba(72, 152, 255, 0) 100%
                );
            }
            > p {
                font-weight: bold;
                color: #002062;
                line-height: 37px;
                font-size: 28px;
                margin-bottom: 8px;
            }
            > span {
                font-weight: normal;
                color: #6679a1;
                line-height: 25px;
                font-size: 18px;
            }
        }
    }
}
.business-box {
    flex-wrap: wrap;
    border-right: 1px solid #4898ff;
    border-bottom: 1px solid #4898ff;

    .model-tit {
        border: 0;
        padding: 0;
    }
    > div {
        position: relative;
        background: #ffffff;
        height: 231px;
        max-width: 25%;
        width: 300px;

        .title {
            font-weight: bold;
            color: #002062;
            line-height: 29px;
            font-size: 22px;
        }
        .tip {
            color: #ffffff;
            line-height: 32px;
            font-size: 18px;
        }
        .icon {
            width: 52px;
            position: absolute;
            right: 25px;
            bottom: 25px;
        }
        .title-en {
            font-size: 16px;
            color: #dbdfe9;
            line-height: 21px;
        }
        &:hover .cube {
            //transform: translateZ(-115px) rotateX(-90deg);
        }
    }
    .cube {
        width: 100%;
        height: 100%;
        position: relative;
        -webkit-transform-style: preserve-3d;
        transition: 0.5s all;
        -webkit-transform: translateZ(-115px) rotate(0deg);
    }
    .cube li {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 24px 23px;
        border: 1px solid #4898ff;
        height: 231px;
        border-bottom: 0;
        border-right: 0;
    }

    .cube li:nth-of-type(2) {
        background: #4898ff;
        // transform-origin: top;
        //  border: 0;
        // transform: translateZ(-115px) rotateX(90deg);
        .title {
            color: #fff;
            margin-bottom: 16px;
        }
    }
    .cube li:nth-of-type(3) {
        background: #fff;
        top: 0;
        left: 0;
        transform: translateZ(115px);
        transition: opacity 0.3s;

        &:hover {
            opacity: 0;
        }
    }
}
.arrow-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}
.qrcode-box {
    position: fixed;
    right: 100px;
    top: 50%;
    z-index: 111;
    transform: translate(0px, -50%);
}
.qr-item {
    width: 160px;
    height: 182px;
    background: #ffffff;
    box-shadow: 0px 5px 19px 0px rgba(205, 216, 238, 0.4);
    border-radius: 4px;
    padding: 8px 0;
    position: relative;
    text-align: center;

    > p {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        margin-top: 4px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        height: 2px;
        background: #ff8f4a;
        left: 0;
        right: 0;
    }
    &.item2 {
        margin-top: 25px;
        &::after {
            background: #4898ff;
        }
    }
}

.btn-box {
    margin-top: 88px;
}
.mar-t-60 {
    margin-top: 60px;
}
.mar-t-80 {
    margin-top: 80px;
}
.mar-b-47 {
    margin-bottom: 47px;
}
.mar-b-20{
    margin-bottom: 20px;
}

.mar-b-31 {
    margin-bottom: 31px;
}

.fadeLf1 {
    animation: fadeLeft 1s;
}
.fadeLf2 {
    animation: fadeLeft 1s;
    animation-delay: 0.1s;
}
.fadeRi1 {
    animation: fadeRight 1s;
}
.fadeRi2 {
    animation: fadeRight 1s;
    animation-delay: 0.1s;
}

@keyframes fadeLeft {
    0% {
        opacity: 0;
        transform: translate(100%, 0px);
    }
    50% {
        opacity: 0.4;
        //transform: translate(50%, 0px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

@keyframes fadeRight {
    0% {
        opacity: 0;
        transform: translate(-100%, 0px);
    }
    50% {
        opacity: 0.4;
        //transform: translate(50%, 0px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0px);
    }
}
</style>
<style lang="scss">
.home {
    .banner .el-carousel__container {
        height: 650px;
    }
    .tabs-carousel .el-carousel__container {
        height: 535px;
    }
    .el-tabs__header {
        margin: 0;
    }
    .el-tabs__nav {
        display: flex;
        width: 100%;
        padding: 0 60px;
    }
    .el-tabs__item {
        font-size: 20px;
        color: #002062;
        line-height: 26px;
        height: 45px;
        flex: 1;
        width: 40px;
        // padding-left: 40px;
        // &+.el-tabs__item{

        // }
    }
    .el-tabs__nav-wrap::after {
        color: #efefef;
        height: 1px;
    }
    .el-tabs__active-bar {
        width: 40px !important;
        margin-left: 100px;
        height: 4px;
        background: #4898ff;
        border-radius: 2px;
    }
}
</style>
