<template>
    <div class="tabsContent" :class="{ flexPic: flexPic }">
        <div class="flex">
            <div class="img-box" v-if="projectInfo.pos == 'right' || flexPic">
                <img :src="projectInfo.pic" />
            </div>
            <div class="text-box flex1">
                <p class="title">{{ projectInfo.title }}</p>
                <p class="info mar-b-16" v-if="projectInfo.info">
                    {{ projectInfo.info }}
                </p>
                <div
                    class="mar-b-18"
                    v-for="(item, idx) in projectInfo.list"
                    :key="idx"
                >
                    <div class="list-title">
                        <img
                            src="@/assets/img/list-icon.png"
                            width="20px"
                        /><span>{{ item.title }}</span>
                    </div>
                    <p class="info">{{ item.info }}</p>
                </div>
                <div class="item-btn flex fl-ai-c fl-ju-c" v-if="flexPic" @click="$emit('clickBtn',index+1)">查看详情</div>
            </div>
            <div class="img-box" v-if="projectInfo.pos == 'left' && !flexPic">
                <img :src="projectInfo.pic" />
            </div>
        </div>
    </div>
</template>
<script>
 
 import image1 from '@/assets/img/project-pic1.png'
 import image2 from '@/assets/img/project-pic2.png'
 import image3 from '@/assets/img/project-pic3.png'
 import image4 from '@/assets/img/project-pic4.png'
 import image5 from '@/assets/img/project-pic5.png'
 import image6 from '@/assets/img/project-pic6.png'
 import image7 from '@/assets/img/project-pic7.png'
export default {
props:[
    'index',
    'flexPic'//固定左图右文
    ],
  data(){
      return{
          menuList:[
              {title:'资源赋能',link:'Home',info:'构建全链路、全角色、全周期的非标大宗交易服务生态',list:[
                  {title:'全链路',info:'非标产业全链条赋能覆盖交易过程，打造全自助式线上交易生态闭环。'},
                  {title:'全周期',info:'金融生态赋能 覆盖产业全生命周期 多业务场景服务。'},
                  {title:'全角色',info:'开发商、合伙人、消费者、政府、生态服务机构，平台+生态为合作伙伴创造多种价值。'},
                ],pic:image1,pos:'left'},
              {title:'效率赋能',link:'Business',info:'',list:[
                  {title:'全线上一站式自助交易平台',info:'资产售卖一站解决，行业政策一网通知，权证服务一网通办，自助交易全线上，不用出门全办好。'},
                  {title:'大数据+AI智能算法，精准匹配',info:'将AI、大数据、云计算等先进算法落地应用到好生活交易场景，研发具有行业领先水平的智能推荐、智能交易、自动化决策系统，服务于智慧交易平台，并赋能与生态体系伙伴。'},
                ],pic:image2,pos:'right'},
              {title:'好生活·好房',link:'Proservice',info:'业内领先的一站式房产自助交易平台，旨在让您的房产交易更加便捷。',list:[
                  {title:'明房源',info:'透明房源、实时销控，你的掌上售楼处。'},
                  {title:'明优惠',info:'买房优惠、会员专享，线上支付更便捷。'},
                ],pic:image3,pos:'left'},
              {title:'好生活·经纪人',link:'Hotnews',info:'',list:[
                  {title:'高额佣金',info:'全国楼盘均可销售，品牌房企高额佣金跨越城市不受限制'},
                  {title:'轻松推介',info:'在线拓客功能全面，推介进程实时反馈。'},
                  {title:'快速兑现',info:'平台联合强大的金融资源，为合伙人提供佣金秒结秒付赋能，实现合伙人销售即结算，所见即所得。'},
                ],pic:image4,pos:'right'},
              {title:'合伙人商学院',link:'Talent',info:'平台面向不同画像的合伙人，建立2+2形式，创造合伙人终身成长体系。',list:[
                  {title:'顶级师资+系统教程',info:'元知智能研究院链接全球顶级高校资源（哈佛、沃顿、北大、清华等），以集团六大高校为系统化教程输出，通过合商学院实现线上授课。'},
                  {title:'线上+线下',info:'建立区域示范性培训基地，共同为企业不断培育各种类型创新型人才，并通过职业技能培训有效帮扶各类人才实现1+x增能转型和个人创业就业帮扶。为夫妻老婆店提供500+合伙人大学培训课程、劳务关系服务，服务于小微企业高效经营。'},
                ],pic:image5,pos:'left'},
              {title:'开发商Saas',link:'Partnerv',info:'贯通壹链盟、广告策划、合伙人销售、网红直播等多类服务，面向开发商提供交易场景全生态的服务平台。',list:[
                  {title:'营销策划',info:'3D只能云设计，超强大营销广告策划，可视化电子楼书等营销工具，实现线上裂变。'},
                  {title:'网红直播',info:'2000+流量网红在线提供直播卖房服务，链接在线售楼处，线上实现房源认筹、认购。'},
                  {title:'金融服务',info:'壹链盟、壹保通、大数据风控，金融科技助力产业升级。'},
                ],pic:image6,pos:'right'},
                {title:'好生活管家',link:'Partnerv',info:'针对企业用户，提供包括打卡，考勤设置，记录统计，补卡等日常考勤管理功能，同时可配合好生活经纪人软件进行运营管理，方便合伙人跟进看房交易流程、佣金结算等',list:[
                  {title:'考勤管理',info:'打卡签到，考勤设置，打卡记录统计分析，补卡申请等功能，极大节省日常考勤管理工作量'},
                  {title:'合伙人带看成交',info:'配合好生活经纪人软件，帮助合伙人完成扫码带看，跟进看房交易过程，在成交后线上申请快速结算佣金。'},
                ],pic:image7,pos:'left'},
          ]
      }
  },
  components: {
     
  },
  computed:{
      projectInfo(){
          return this.menuList[this.index||0]
      }
  },
}
</script>
<style lang="scss" scoped>
.tabsContent {
    padding: 0 45px;
    text-align: left;

    &.flexPic {
        .text-box {
            margin: 30px 0 0 24px;
        }
    }
}
.text-box {
    margin: 30px 35px 0 0;
    .title {
        font-size: 30px;
        font-weight: bold;
        color: #002062;
        line-height: 40px;
        margin-bottom: 8px;
    }
}
.list-title {
    margin-bottom: 10px;
    span {
        font-size: 20px;
        font-weight: bold;
        color: #002062;
        line-height: 30px;
        vertical-align: middle;
        margin-left: 15px;
    }
}
.info {
    font-size: 18px;
    color: #002062;
    line-height: 28px;
}
.img-box {
    > img {
        width: 621px;
        flex: 1;
    }
}
.mar-b-16 {
    margin-bottom: 16px;
}
.mar-b-18 {
    margin-bottom: 18px;
}
.item-btn {
    width: 120px;
    height: 44px;
    background: #4898ff;
    box-shadow: 0px 6px 20px 0px rgba(72, 152, 255, 0.32);
    border-radius: 22px;
    font-size: 18px;
    color: #ffffff;
    line-height: 24px;
}
</style>
